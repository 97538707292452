<template>
    <CForm novalidate>
      <CCard no-header>
        <CCardBody>
          <h3>{{ operationName }} Partner Experience Row</h3>
          <hr />
          <CAlert :color="alertType" v-if="message">
            {{ message }}
          </CAlert>
  
          <div>
            <CRow class="justify-content-end">
              <div style="margin-right: 1rem; text-align: right;">
                <div>Active</div>
                <CSwitch
                  color="success"
                  size="lg"
                  :checked.sync="experienceRow.active"
                  style="align-self: flex-end;"
                ></CSwitch>
              </div>
            </CRow>
            <CRow>
              <CCol sm="3">
                <span>Description </span>
                <a @click="showAllDescriptionTranslations = !showAllDescriptionTranslations">
                  <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                </a>
                <div v-for="(language, k) in experienceRow.descriptionTranslations" class="form-group" :key="k">
                  <div v-show="k === 0 || showAllDescriptionTranslations" class="input-group">
                    <div class="input-group-prepend">
                      <span v-text="language.languageCode" class="input-group-text"></span>
                    </div>
                    <input v-model="experienceRow.descriptionTranslations[k].name" type="text" class="form-control" :id="language.languageCode" />
                  </div>
                </div>
              </CCol>
            <CCol col="3">
              <h4>
                <CIcon
                  name="cil-check"
                  style="margin-bottom: 5px"
                  class="text-primary"
                ></CIcon>
                Options / Visibility
              </h4>
              <CRow>
                <CCol>
                  <div class="form-check" style="align-items: flex-start">
                    <div style="margin: 0.25rem">
                      <input
                        v-model="experienceRow.type"
                        :value="0"
                        type="radio"
                        name="visibilityRadio"
                        id="visibilityShowRow"
                      />
                      <label
                        class="form-check-label"
                        for="visibilityShowRow"
                        style="margin-left: 0.2rem"
                      >
                        Visible</label
                      >
                    </div>
                    <div style="margin: 0.25rem">
                      <input
                        v-model="experienceRow.type"
                        :value="2"
                        type="radio"
                        name="visibilityRadio"
                        id="visibilityHideRow"
                      />
                      <label
                        class="form-check-label"
                        for="visibilityHideRow"
                        style="margin-left: 0.2rem"
                      >
                        Hidden</label
                      >
                    </div>
                    <div style="margin: 0.25rem">
                      <input
                        v-model="experienceRow.type"
                        :value="3"
                        type="radio"
                        name="visibilityRadio"
                        id="visibilityOption"
                      />
                      <label
                        class="form-check-label"
                        for="visibilityOption"
                        style="margin-left: 0.2rem"
                      >
                        Option</label
                      >
                    </div>
                    <div style="margin: 0.25rem">
                      <input
                        v-model="experienceRow.type"
                        :value="1"
                        type="radio"
                        name="visibilityRadio"
                        id="visibilityOption"
                      />
                      <label
                        class="form-check-label"
                        for="visibilityOption"
                        style="margin-left: 0.2rem"
                      >
                        Add-on</label
                      >
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="2">
                  <CInput label="Sort order" v-model="experienceRow.sortOrder" />
            </CCol>
            </CRow>
            <CRow>
                      <CCol col="12" class="d-flex flex-row align-items-center">
                        <CIcon name="cil-camera" style="margin-right: 10px" />
                        <h4>Thumbnails and images</h4>
                      </CCol>
                    </CRow>
                    <images-uploader :thumbnails.sync="thumbnails"></images-uploader>
            <CRow style="align-items: flex-end;">
              <CCol col="4">
                <CSelect label="Start day" :options="weekDays" :value.sync="experienceRow.startDay" />
              </CCol>
              <CCol>
                <h4><CIcon name="cil-storage" style="margin-bottom: 5px" class="text-primary" /> Inventory</h4>
                <div style="display: flex;">
                  <CSelect label="Partner" :plain="true" :options="partners" :value.sync="experienceRow.partnerId" @change="onChangePartner($event)" style="width: 45%;"></CSelect>
                  <CSelect label="Experience" :plain="true" :options="partnerExperiences" v-model="experienceRow.experienceId" @change="onChangeExperience($event)" style="width: 45%; margin-left: 1rem;"></CSelect>
                </div>
              </CCol>
            </CRow>
            <CRow style="align-items: flex-start; margin-top: 1rem;">
              <CCol col="4">
                <h4><CIcon name="cil-calculator" style="margin-bottom: 5px" class="text-primary" /> Guest Pricing</h4>
                <p>Price adjustments</p>
                <div v-if="experienceRow.priceAdjustments">
                  <CInput v-for="pa in experienceRow.priceAdjustments" v-model="pa.rate" :label="pa.label + ' %'" :key="pa.label"/>
                </div>
              </CCol>
              <CCol>
                <h4><CIcon name="cil-check" style="margin-bottom: 5px" class="text-primary" /> Options</h4>
                <input v-model="experienceRow.includeOptions" id="inclPartnerOptions" type="checkbox" style="margin: 0.5rem;" />
                <label for="inclPartnerOptions">Include partner's options</label>
              </CCol>
            </CRow>
          </div>
  
          <CRow style="padding-top: 40px">
            <CCol col="8"></CCol>
            <CCol class="d-flex justify-content-end">
              <CButton color="secondary" class="btn-lg" @click="goBack"
                ><CIcon name="cil-mood-very-good" /> Back to experience</CButton
              >
  
              <CButton
                color="primary"
                @click="save()"
                style="margin-left: 15px"
                class="btn-lg"
                ><CIcon name="cil-save" /> Save</CButton
              >
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  </template>
  
  <script>
  import axios from "axios";
  import common from "@/common"
  import ImagesUploader from "@/components/ImagesUploader";

  export default {
    name: "EditPartnerExperienceRow",
    components:{
      ImagesUploader
    },
    props: {
      thumbnails: [],
    },
    data: () => {
      return {
        token: localStorage.getItem("api_token"),
        partnerId: localStorage.getItem("partner_id"),
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        experienceScheduleId: null,
        experienceDayId: "00000000-0000-0000-0000-000000000000",
        operationName: "Create New",
        showAllDescriptionTranslations: false,
        languages: [],
        taxClasses: [],
        weekDays: [],
  
        experienceRow: {
          id: "00000000-0000-0000-0000-000000000000",
          active: true,
          descriptionTranslations: [],
          startDay: null,
          partnerId: null,
          experienceId: null,
          includeOptions: false,
          priceAdjustments: [],
          thumbnailUrl: null,
          type: 0,
        },
  
        guestTypes: [],
        partners: [],
        partnerExperiences: [],
  
        // alert
        alertType: "danger",
        message: null,
      };
    },
    methods: {
      handleFileUpload(event) {
      this.experienceRow.file = event.target.files[0];
    },
      onChangePartner(event) {
        this.experienceRow.partnerId = event.target.value;
        this.getPartnerExperiences();
      },
      onChangeExperience(event) {
        this.experienceRow.experienceId = event.target.value;
        if (this.experienceRow.experienceId) this.getGuestTypes();
      },
      addGuestTypeLabel(guestTypeData, expRowData) {
        const expRowGuestTypeIdx = expRowData.findIndex(g => g.guestTypeId === guestTypeData.value);
        if (expRowGuestTypeIdx > -1) {
          let modifiedData = { ...expRowData[expRowGuestTypeIdx], label: guestTypeData.label };
          expRowData.splice(expRowGuestTypeIdx, 1, modifiedData); // https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
        }
        return expRowGuestTypeIdx;
      },
      goBack(status, evt, accept) {
        this.$router.go(-1);
      },
      getTaxClasses() {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/ListItem/TaxClasses`)
          .then(function (response) {
            if (response && response.data) self.taxClasses = response.data;
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getWeekDays() {
        if (!this.experienceScheduleId) {
          return;
        }
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/ListItem/AvailableStartDays?scheduleId=${this.experienceScheduleId}`)
          .then(function (response) {
            if (response && response.data) self.weekDays = response.data;
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getLanguages() {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/ListItem/Languages?showDefaultValue=false`)
          .then(function (response) {
            if (response && response.data) self.languages = response.data;
            for (let language of self.languages) {
              const existingLanguage = self.experienceRow.descriptionTranslations.find(d => d.languageId === language.value);
              if (!existingLanguage) self.experienceRow.descriptionTranslations.push({
                languageCode: language.label,
                name: null,
                languageId: language.value,
              });
            }
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getGuestTypes() {
        let self = this;
        axios
          .get(this.$apiAdress + '/v1/ListItem/GuestTypeList?partnerId=' + this.experienceRow.partnerId + '&experienceId=' + this.experienceRow.experienceId)
          .then((response) => {
            if (response && response.data) {
              this.guestTypes = response.data;
              if (this.operationName === 'Edit') {
                if (!this.experienceRow.priceAdjustments) this.experienceRow.priceAdjustments = [];
                for (let guestType of this.guestTypes) {
                  const idx = this.addGuestTypeLabel(guestType, this.experienceRow.priceAdjustments);
                  if (idx === -1) {
                    this.experienceRow.priceAdjustments.push({label: guestType.label, guestTypeId: guestType.value, rate: 0});
                  }
                }
              } else {
                this.experienceRow.priceAdjustments = [];
                for (let guestType of this.guestTypes) {
                  this.experienceRow.priceAdjustments.push({label: guestType.label, guestTypeId: guestType.value, rate: 0});
                }
              }
            }
          })
          .catch((error) => {
                      this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
            this.message = error;
          });
      },
      getPartners() {
        let self = this;
        axios
          .get(this.$apiAdress + '/v1/ListItem/PartnerList')
          .then((response) => {
            if (response && response.data) this.partners = response.data;
          })
          .catch((error) => {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getPartnerExperiences() {
        let self = this;
        axios
          .get(this.$apiAdress + '/v1/ListItem/ExperienceListByPartnerId?selectedPartnerId=' + this.experienceRow.partnerId)
          .then((response) => {
            if (response && response.data) {
              this.partnerExperiences = response.data;
              //this.getGuestTypes();
            }
          })
          .catch((error) => {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      save() {
        let self = this;
        const saveObj = {
          id: this.experienceRow.id,
          active: this.experienceRow.active,
          dayId: this.experienceDayId,
          startDay: this.experienceRow.startDay,
          partnerId: this.experienceRow.partnerId,
          experienceId: this.experienceRow.experienceId,
          descriptionTranslations: this.experienceRow.descriptionTranslations,
          includeOptions: this.experienceRow.includeOptions,
          type:this.experienceRow.type,
          priceAdjustments: this.experienceRow.priceAdjustments?.map((priceAdjustment) => ({ rate: priceAdjustment.rate, guestTypeId: priceAdjustment.guestTypeId })),
          file: this.experienceRow.file
        };
        var formData = new FormData();
        common.buildFormData(formData, saveObj);
        let newImages = self.thumbnails?.filter((thumbnail) => thumbnail.file);
      let thumbnailsUrls = self.getThumbnailsUrls();
      if (newImages) {
        let newImagesOrders = [];
        newImages.forEach((newImage) => {
          formData.append("files", newImage.file);
          newImagesOrders.push({
            fileName: newImage.file.name,
            orderNumber: newImage.order,
          });
        });
        formData.append("newImagesOrdersJson", JSON.stringify(newImagesOrders));
      }

      if (thumbnailsUrls) formData.set("thumbnailUrl", thumbnailsUrls);
        axios
          .post(
            `${this.$apiAdress}/v1/PartnerExperience/${this.experienceDayId}`,
            formData
          )
          .then((response) => {
                      this.alertType = "success";
          setTimeout(function () {
              this.message = null;
          }, 5000);
            this.message = "Successfully updated experience schedule.";
            this.experienceRow.id = response.data;
          })
          .catch((error) => {
                      this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
            this.message = error.response.data.errors;
          });
      },
      getThumbnailsUrls() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.url)
          .map((thumbnail) => {
            return thumbnail.url;
          })
          .join();
      } else return null;
    },
    getThumbnailsFiles() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.file)
          .map((thumbnail) => {
            return thumbnail.file;
          });
      } else return null;
    },
      get() {
        let self = this;
        if (self.experienceRow.id === self.emptyGuid) return;
        axios
          .get(`${this.$apiAdress}/v1/PartnerExperience/${self.experienceDayId}/${self.experienceRow.id}`)
          .then(function (response) {
            if (response && response.data) {
              self.experienceRow = response.data;
              let thumbnailOrderIndex = 0;
            self.thumbnails = response.data.thumbnailUrl
              ?.split(",")
              .map((thumbnail) => {
                return {
                  url: thumbnail,
                  order: thumbnailOrderIndex++,
                  blob: null,
                  file: null,
                  show: false,
                };
              });
              if (self.operationName === 'Edit') {
                self.getPartnerExperiences();
                //self.getGuestTypes();
              }
            }
            self.getLanguages();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
    },
    mounted: function () {
      let self = this;
      self.experienceDayId = this.$route.params.dayId;
      self.experienceRow.id = this.$route.params.id;
      self.experienceScheduleId = this.$route.query.scheduleId;
      self.experienceRow.experienceId = this.$route.query.experienceId;
      
      self.get();
      self.getTaxClasses();
      self.getWeekDays();
      self.getPartners();
  
      if (self.experienceRow.id !== null &&  self.experienceRow.id !== this.emptyGuid) {
        this.operationName = "Edit";
      } else {
        self.getLanguages();
      }
  
    },
  };
  </script>